/* eslint-disable react/prop-types */
import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import H1 from '../components/header1';
import TextGridComponent from '../components/voices-of-vow/TextGridComponent';
import { BlackLogoBand } from '../components/logoBands';
import ImageAndTextComponent from '../components/voices-of-vow/ImageAndTextGridComponent';
import SEO from '../components/seo';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 60,
    marginBottom: '40px',
    // paddingLeft: '80px',
    '& h3': {
      marginTop: 0,
      maxWidth: 600,
      marginBottom: '1rem',
    },
  },
  logoBandPosition: {
    marginBottom: '-95px',
  },
  image: {
    maxHeight: 390,
    marginBottom: '-2rem',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const VoicesOfVowTemplate = ({ data }) => {
  const classes = useStyles();

  const imageFluid =
    data.allContentfulVoicesOfVowPost.edges[0].node.image.fluid;
  const text =
    data.allContentfulVoicesOfVowPost.edges[0].node
      .childContentfulVoicesOfVowPostMainContentTextDescriptionRichTextNode
      .json;
  const onClickWorkWithUsButton = () => {
    navigate('/work-with-us');
  };

  const workWithUsButton = {
    text: 'Work With Us',
    onClick: onClickWorkWithUsButton,
  };

  return (
    <>
      <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <SEO ogimage={imageFluid.src} />
        <Container maxWidth="xl">
          <H1 title="VOICES OF VOW" />
        </Container>
        <Img
          fluid={imageFluid}
          alt="voices-of-vow-image"
          className={classes.image}
        />
        <Grid container className={classes.gridContainer}>
          <TextGridComponent text={text} />
          <ImageAndTextComponent
            fluid={imageFluid}
            alt="voices-of-vow-image"
            button={workWithUsButton}
            bodyText="Visit our recruitment section and fill in the contact form to
              submit an open application."
          />
        </Grid>
      </Container>
      <div className={classes.logoBandPosition}>
        <BlackLogoBand />
      </div>
    </>
  );
};

export default VoicesOfVowTemplate;

export const pageQuery = graphql`
  query VoicesOfVowNySlug($slug: String!) {
    allContentfulVoicesOfVowPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          childContentfulVoicesOfVowPostMainContentTextDescriptionRichTextNode {
            json
          }
          image {
            fluid(quality: 90, maxWidth: 1383, maxHeight: 720) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
